<template>
  <v-layout row wrap>
    <v-flex xs12 v-if="isLoading">
      <v-progress-linear indeterminate color="green" :active="isLoading"></v-progress-linear>
    </v-flex>
    <v-flex v-else xs10 offset-xs1 class="mb-3">
      <v-card>
        <v-toolbar flat dark dense color="primary">
          <v-toolbar-title>{{ $tc('_rolledUpJournalReport', 2) }}</v-toolbar-title>
          <tooltip-select tip="_rolledUpJEReport" class="tw-ml-2"></tooltip-select>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-layout row wrap class="pa-3">
          <v-flex xs2 px-2>
            <v-menu
              lazy
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              full-width
              :nudge-right="40"
              max-width="290px"
              min-width="290px"
            >
              <v-text-field slot="activator" label="Start Date" v-model="startDate" readonly></v-text-field>
              <v-date-picker v-model="startDate" no-title scrollable actions> </v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs2 px-2>
            <v-menu
              lazy
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              full-width
              :nudge-right="40"
              max-width="290px"
              min-width="290px"
            >
              <v-text-field slot="activator" label="End Date" v-model="endDate" readonly></v-text-field>
              <v-date-picker v-model="endDate" no-title scrollable actions> </v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs2 px-2>
            <v-select
              :items="['Contact', 'Category']"
              :label="$t('_journalGrouping')"
              v-model="groupBy"
              return-object
            ></v-select>
          </v-flex>
          <v-flex xs4 px-2>
            <v-select
              :items="connectionList"
              :label="$t('_accountingConnection')"
              item-text="provider"
              item-value="id"
              v-model="connection"
              return-object
            >
              <template v-slot:selection="{ item }">
                <img class="tw-h-6 tw-mr-3" :src="getProviderIcon(item.provider)" />{{ getConnectionName(item) }}
              </template>
              <template v-slot:item="{ item }">
                <img class="tw-h-6 tw-mr-3" :src="getProviderIcon(item.provider)" />{{ getConnectionName(item) }}
              </template>
            </v-select>
          </v-flex>
          <v-flex xs2>
            <v-btn :disabled="runReportBtnDisabled" @click="runReport">{{ $tc('_runReport') }}</v-btn>
          </v-flex>
          <v-flex xs12>
            <v-progress-linear indeterminate color="green" :active="isLoading"></v-progress-linear>
          </v-flex>
          <v-flex xs12 v-if="hasError">
            <v-alert type="warning" :value="hasError"> Problem Generating Report </v-alert>
          </v-flex>
          <v-flex xs12>
            <v-footer>
              {{ $tc('_timezone') + ': ' + currentTimezone }}
            </v-footer>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
    <v-flex xs10 offset-xs1 v-if="showReport">
      <v-layout justify-end>
        <v-flex shrink>
          <report-html-to-csv
            v-if="reportDoc"
            :reportDoc="reportDoc"
            :fileName="`JournalReport_${startDate}_${endDate}`"
            :repeatIndexes="[0]"
            :changeReporttotal="true"
            :reporttotalIndex="2"
          />
        </v-flex>
      </v-layout>
      <v-card>
        <v-progress-linear v-if="reportLoading" indeterminate color="green" :active="reportLoading"></v-progress-linear>
        <iframe
          @load="frameLoaded"
          :srcdoc="reportDoc"
          frameborder="0"
          style="overflow: hidden; height: 700px; width: 100%"
          height="700px"
        ></iframe>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from 'axios';
import gql from 'graphql-tag';

import { ConnectionCategory, Providers } from '@/api-svc-types';
import ReportHTMLToCSV from '@/components/reports/ReportHTMLToCSV.vue';
import { InactivityWatcher } from '@/inactivityWatcher';
import { getAccountingProviderIcon } from '@/utils/accountingProviders';
import { requestParentToKeepActive } from '@/utils/iframeMessageRequester';

export default {
  components: {
    'report-html-to-csv': ReportHTMLToCSV,
  },
  data: () => ({
    date: '',
    startDate: null,
    endDate: null,
    connection: null,
    isLoading: false,
    connections: [],
    showReport: false,
    reportUrl: null,
    hasError: false,
    errors: [],
    reportLoading: false,
    inactivityWatcherKeepActive: null,
    reportDoc: null,
    groupBy: 'Contact',
  }),
  apollo: {
    connections: {
      query: gql`
        query GetConnections($orgId: ID!) {
          connections(orgId: $orgId, overrideCache: true) {
            id
            provider
            lastSyncSEC
            category
            name
            isDeleted
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      update(data) {
        if (data && data.connections) {
          return data.connections.filter((x) => !x.isDeleted && x.category === ConnectionCategory.AccountingConnection);
        } else {
          return [];
        }
      },
      loadingKey: 'isLoading',
    },
  },
  computed: {
    connectionList() {
      const categories = this.$store.getters['categories/CATEGORIES'];
      const contacts = this.$store.getters['contacts/ENABLED_CONTACTS'];

      let connections = this.connections;

      if (
        (contacts?.some((x) => !x.accountingConnectionId || x.accountingConnectionId === 'Manual') ?? false) ||
        (categories?.some((x) => !x.accountingConnectionId || x.accountingConnectionId === 'Manual') ?? false)
      ) {
        const manualAccountingConnection = {
          id: 'Manual',
          provider: Providers.Manual,
        };
        connections = connections.concat(manualAccountingConnection);
      }

      if (connections && connections.length > 0) {
        return connections;
      } else {
        return [{ provider: 'None', id: 'none' }];
      }
    },
    currentTimezone() {
      return this.$store.state.currentOrg.timezone ? this.$store.state.currentOrg.timezone : 'N/A';
    },
    runReportBtnDisabled() {
      return this.startDate === null || this.endDate === null || this.connection === null;
    },
  },
  methods: {
    frameLoaded() {
      this.reportLoading = false;
      requestParentToKeepActive('report', false);
      this.inactivityWatcherKeepActive?.dispose();
    },
    async fetchReport() {
      const response = await axios.get(this.reportUrl, { withCredentials: true });
      const data = response.data;
      return data;
    },
    async runReport() {
      this.reportLoading = true;
      this.showReport = true;
      this.reportDoc = null;

      // Do some validation here
      const orgId = this.$store.state.currentOrg.id;
      const connectionId = this.connection.id;

      let reportUrl =
        this.getReportApiUrl() + `reports/view?type=RolledUpJournalReport&orgId=${orgId}&connectionId=${connectionId}`;

      if (this.startDate) {
        reportUrl += `&startDate=${this.startDate}`;
      }

      if (this.endDate) {
        reportUrl += `&endDate=${this.endDate}`;
      }

      reportUrl += `&groupBy=${this.groupBy}`;

      this.reportUrl = reportUrl;
      this.fetchReport().then((res) => {
        this.reportDoc = res;
      });
      this.reportLoading = true;
      this.showReport = true;

      requestParentToKeepActive('report', true);
      this.inactivityWatcherKeepActive = InactivityWatcher.instance?.keepActive(
        () => this.reportLoading && document.contains(this.$el)
      );
    },
    getProviderIcon(provider) {
      return getAccountingProviderIcon(provider);
    },
    getConnectionName(conn) {
      let name = conn.name;
      if (conn.provider === 'Manual') {
        name = 'Bitwave';
      }

      if (!name) {
        name = conn.provider;
      }

      return name;
    },
  },
};
</script>
<style scoped>
.header-row {
  text-align: right;
}
.input-row {
  color: blue;
  text-align: right;
}
.total-row {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  font-weight: bold;
  text-align: right;
  height: 25px;
}
</style>
